<template>
    <div class="successionManagement" style="height: 100%;">
        <div class="top-heaer">
            <el-row>
                <el-col :span="24">
                    <div class="grid-content bg-purple-dark d-flex">
                        <div class="d-flex">
                            <div class="lable">
                                <p>车间选择</p>
                            </div>
                            <div>
                                <el-select v-model="workShopId" @change="getWorkShopId" placeholder="请选择车间">
                                    <el-option
                                            v-for="item in workShopList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="d-flex" style="margin-left: 50px;">
                            <div class="lable">
                                <p>工序选择</p>
                            </div>
                            <div>
                                <el-select v-model="processId" placeholder="请选择工序">
                                    <el-option
                                            v-for="item in processList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="d-flex" style="margin-left: 50px;">
                            <div class="lable">
                                <p>上班日期</p>
                            </div>
                            <div>
                                <el-date-picker v-model="occurTime" @change="getMachineId"
                                                value-format="yyyy-MM-dd HH:mm:ss"
                                                type="datetime"
                                                :clearable="false"
                                                placeholder="选择日期" style="width:100%;">
                                </el-date-picker>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div>
            <div class="select-box d-flex">
                <div class="btn-box">
                    <el-button type="primary" class="btn" :class="isIndex == 0 ? 'btn-ac' : ''" @click="standAlone">
                        单机模式
                    </el-button>
                </div>
                <div class="btn-box">
                    <el-button type="primary" class="btn" :class="isIndex == 1 ? 'btn-ac' : ''" @click="batch">
                        批量模式
                    </el-button>
                </div>
            </div>
        </div>
        <div class="table" style="height: calc(100% - 250px);" v-show="showPiLiang">
            <el-table
                    :data="tempList"
                    border
                    height="100%"
                    ref="table"
                    style="width: 100%">
                <el-table-column
                        prop="workStartTime"
                        label="上班日期"
                        align="center"
                        width="">
                </el-table-column>
                <el-table-column
                        prop="workShopName"
                        label="车间"
                        align="center"
                        width="">
                </el-table-column>
                <el-table-column
                        prop="shiftName"
                        label="班制"
                        align="center"
                        width="">
                </el-table-column>
                <el-table-column
                        prop="groupName"
                        label="班组"
                        align="center"
                        width="">
                </el-table-column>
                <el-table-column
                        prop="machineCode"
                        label="设备"
                        align="center"
                        width="">
                </el-table-column>
                <el-table-column
                        prop="onDutyUserName"
                        label="当班人员"
                        align="center"
                        width="">
                </el-table-column>
                <el-table-column
                        prop="postName"
                        label="岗位"
                        align="center"
                        width="">
                </el-table-column>
                <el-table-column
                        prop="afterWorkProductName"
                        label="交班品种"
                        align="center"
                        width="">
                </el-table-column>
                <el-table-column
                        prop="transferUserName"
                        label="交班人员"
                        align="center"
                        width="160px">
                    <template slot-scope="scope">
                        <el-select v-model='scope.row.transferUserInformationList' multiple placeholder="请选择交接人员">
                            <el-option
                                    style="width:100px"
                                    v-for="item in userList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.userId">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="workIotLength"
                        label="上班机台米数"
                        align="center"
                        width="120px">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.workIotLength" type="number"/>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="afterWorkIotLength"
                        label="下班机台米数"
                        align="center"
                        width="120px">
                    <template slot-scope="scope">
                        <el-input type="number" @change="getTempYield(scope.row)" v-model="scope.row.afterWorkIotLength"/>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="forecastYield"
                        label="预估产量"
                        align="center"
                        width="100px">
                    <template slot-scope="scope">
                        <el-input type="number" v-model="scope.row.forecastYield"/>
                    </template>
                </el-table-column>
                <el-table-column
                        label="操作"
                        align="center"
                        width="50px">
                    <template slot-scope="scope">
                        <el-button
                                @click.native.prevent="deleteRow(scope.$index, tempList)"
                                type="text"
                                size="small">
                            移除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-button type="primary" style="float: right;margin-right:68px;margin-top: 20px;" @click="subMachineDateList"
                       class="bg7caff5 border-none">提交
            </el-button>
        </div>

        <div v-show="showDanJi" style="height: calc(100% - 200px);overflow: auto;">
            <div class="combined-query d-flex" style="justify-content: space-between">
                <div class="grid-content bg-purple-dark d-flex">
                    <div class="d-flex">
                        <div class="lable">
                            <p>设备选择</p>
                        </div>
                        <div @click="dialogVisible = true">
                            <el-input
                                    @click.native="dialogVisible = true"
                                    :disabled="true"
                                    placeholder="请点击选择设备"
                                    v-model="machineInfo.code"
                            >
                            </el-input>
                        </div>
                        <!--:key="Math.random()" @blur.native.capture="getTempYield(scope.row,scope.$index)"-->
                        <!--<div style="margin-left: 10px;">-->
                            <!--<el-button type="primary" class="bg7caff5 border-none">上一个</el-button>-->
                            <!--<el-button type="primary" class="bg7caff5 border-none">下一个</el-button>-->
                        <!--</div>-->
                    </div>
                </div>
            </div>
            <el-dialog
                    title="设备选择"
                    :visible.sync="dialogVisible"
                    width="50%"
            >
                <el-button style="width:120px;margin-left: 10px;margin-bottom: 15px" v-for="item in machineList"
                           :key="item.id" @click="getOneMachine(item)" :type="item.useStatus == '1'?'success':'info'"
                           plain>
                    {{item.code}}
                </el-button>
                <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
  </span>
            </el-dialog>
            <div style="display: flex;margin-top: 20px;">
                <div style="width: 45%;background-color: #FFF;border-radius: 13px;overflow: hidden">
                    <div style="height: 58px; line-height:58px;background-color: #AAA;padding-left:20px;color: #FFF;">
                        上班信息
                    </div>
                    <el-form style="margin-left:20px;margin-right: 40px;padding-top: 20px;"
                             label-width="120px" :rules="rules" :model="handoverForm">
                        <el-col :span="12">
                            <el-form-item label="班制信息:" prop="shiftName">
                                <el-input disabled v-model="handoverForm.shiftName"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="接班品种信息:" prop="workProductName">
                                <el-input disabled v-model="handoverForm.workProductName"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="班组信息:" prop="groupName">
                                <el-input disabled v-model="handoverForm.groupName"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="上班机台米数:" prop="workIotLength">
                                <el-input type="number" v-model="handoverForm.workIotLength"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="上班时间:" prop="workStartTime">
                                <el-input disabled v-model="handoverForm.workStartTime"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="12">
                            <el-form-item label="当班人员:">
                                <el-input disabled v-model="handoverForm.onDutyUserName">
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="岗位信息:">
                                <el-input disabled v-model="handoverForm.postName">
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-form>
                </div>
                <div style="width: 45%;background-color: #FFF;margin-left: 5%;border-radius: 13px;overflow: hidden">
                    <div style="height: 58px;line-height:58px;background-color: #5A9CF8;padding-left:20px;color: #FFF;">
                        下班信息
                    </div>
                    <el-form style="margin-left:20px;margin-right: 40px;padding-top: 20px;"
                             label-width="120px" :rules="rules" :model="handoverForm">
                        <el-col :span="12">
                            <el-form-item label="班制信息:" prop="shiftName">
                                <el-input disabled v-model="handoverForm.shiftName"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="交班品种信息:" prop="afterWorkProductName">
                                <el-input disabled v-model="handoverForm.afterWorkProductName"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="班组信息:" prop="groupName">
                                <el-input disabled v-model="handoverForm.groupName"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="下班机台米数:" prop="afterWorkIotLength">
                                <el-input type="number" @change="getYield" v-model="handoverForm.afterWorkIotLength"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="下班时间:" prop="afterWorkEndTime">
                                <el-input disabled v-model="handoverForm.afterWorkEndTime"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="预估产量:" prop="forecastYield">
                                <el-input v-model="handoverForm.forecastYield"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="当班人员:">
                                <el-input disabled v-model="handoverForm.onDutyUserName">
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="岗位信息:">
                                <el-input disabled v-model="handoverForm.postName">
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="交班人员:">
                                <el-select v-model="handoverForm.transferUserInformationList" multiple
                                           placeholder="请选择交接人员">
                                    <el-option
                                            v-for="item in userList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.userId">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-form>
                </div>
            </div>
            <el-button type="primary" style="float: right;margin-right:68px; margin-top: 30px"  @click="subMachineDate"
                       class="bg7caff5 border-none">提交
            </el-button>
        </div>
    </div>
</template>

<script>
    import {
        getWorkShop,
        getProcess,
        getMachine,
        echoNowShiftInformation,
        getForecastYield,
        commitMachineDate,
        commitMachineDateList,
        getUserByWorkShopId,
        getTemplateMachineDate
    } from "@/api/changeShiftManagement"

    export default {
        name: "successionManagement",
        data() {
            return {
                rules: {
                    workIotLength: [
                        {required: true, message: '请输入上班机台米数', trigger: 'blur'},
                    ],
                    afterWorkIotLength: [
                        {required: true, message: '请输入下班机台米数', trigger: 'blur'},
                    ],
                    forecastYield: [
                        {required: true, message: '请输入预估产量', trigger: 'blur'},
                    ],
                    transferUserName: [
                        {required: true, message: '请选择交接人员', trigger: 'blur'},
                    ],
                },
                handoverForm: {
                    shiftId: '',
                    shiftName: '',
                    groupId: '',
                    groupName: '',
                    workStartTime: '',
                    afterWorkEndTime: '',
                    workProductName: '',
                    afterWorkProductId: '',
                    afterWorkProductName: '',
                    workIotLength: '',
                    afterWorkIotLength: '',
                    userInformationList: [],
                    transferUserInformationList: [],
                    userList: [],
                    transferUserId: '',
                    transferUserName: '',
                    onDutyUserId: '',
                    onDutyUserName: '',
                    postId: '',
                    postName: '',
                    forecastYield: '',
                    userName: '',
                },
                tempList: [],
                name: '',
                isIndex: 0,
                value: '',
                input: '',
                options: [],
                tableData: [],
                workShopList: [],
                processList: [],
                templateList: [],
                machineId: '',
                machineCode: '',
                workShopId: '',
                processId: '',
                machineList: [],
                dialogVisible: false,
                machineInput: '',
                occurTime: '',
                machineInfo: '',
                beforeValue: '',
                aftetValue: '',
                list: [],
                workShopName: '',
                shiftInformation: '',
                shiftId: '',
                shiftName: '',
                groupId: '',
                groupName: '',
                workStartTime: '',
                afterWorkEndTime: '',
                workProductName: '',
                afterWorkProductId: '',
                afterWorkProductName: '',
                workIotLength: '',
                afterWorkIotLength: '',
                userInformationList: [],
                transferUserInformationList: [],
                userList: [],
                transferUserId: '',
                transferUserName: '',
                onDutyUserId: '',
                onDutyUserName: '',
                postId: '',
                postName: '',
                forecastYield: '',
                showPiLiang: false,
                showDanJi: true,
                userName: '',

            }
        },

        created() {
            getWorkShop().then(res => {
                var date = new Date();
                var year = date.getFullYear(); //月份从0~11，所以加一
                var dateArr = [date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()];
                for (var i = 0; i < dateArr.length; i++) {
                    if (dateArr[i] >= 1 && dateArr[i] <= 9) {
                        dateArr[i] = "0" + dateArr[i];
                    }
                }
                this.occurTime = year + '-' + dateArr[0] + '-' + dateArr[1] + ' ' + dateArr[2] + ':' + dateArr[3] + ':' + dateArr[4];
                this.workShopList = res.data.data;
                this.workShopId = this.workShopList[0].id;
                this.workShopName = this.workShopList[0].name;
                getProcess(this.workShopId).then(res => {
                    this.processList = res.data.data;
                    this.processId = this.processList[0].id;
                    getMachine(this.workShopId, this.processId, this.occurTime).then(res => {
                        this.machineList = res.data.data;
                        getUserByWorkShopId(this.workShopId).then(res => {
                            console.log('res', res)
                            this.userList = res.data.data
                        })
                    })
                })

            })


        },

        methods: {
            getWorkShopId(val) {
                let mList = this.workShopList.find(item => item.id == val)
                this.workShopName = mList.name
                getProcess(val).then(res => {
                    this.processList = res.data.data;

                })
            },
            getMachineId(val) {
                getMachine(this.workShopId, this.processId, val).then(res => {
                    this.machineList = res.data.data;
                })
            },
            getOneMachine(i) {
                this.machineInfo = i
                this.machineId = this.machineInfo.id
                this.dialogVisible = false
                echoNowShiftInformation(this.workShopId, this.processId, this.occurTime, this.machineId).then(res => {
                    this.handoverForm = res.data.data;
                    this.userInformationList = this.handoverForm.userInformationList;
                    let str = '', str1 = '';
                    this.userInformationList.forEach(i => {
                        str = str + i.userName + ','
                        str1 = str1 + i.postName + ','
                    })
                    this.handoverForm.onDutyUserName = str.substr(0, str.length - 1)
                    this.handoverForm.postName = str1.substr(0, str1.length - 1)
                    this.handoverForm.transferUserInformationList = this.handoverForm.transferUserInformationList.map(i => i.userId);
                })


            },

            getYield() {
                getForecastYield(this.machineId, this.occurTime, this.handoverForm.workIotLength,
                    this.handoverForm.afterWorkEndTime, this.handoverForm.afterWorkIotLength,
                    this.handoverForm.afterWorkProductId).then(res => {
                    this.handoverForm.forecastYield = res.data.data
                    this.$forceUpdate()
                })
            },
            getTempYield(row, num) {
                getForecastYield(row.machineId, this.occurTime, row.workIotLength,
                    row.afterWorkEndTime, row.afterWorkIotLength,
                    row.afterWorkProductId).then(res => {
                    row.forecastYield = res.data.data
                    this.$set(row, num, row)
                    this.$forceUpdate()
                })
            },
            subMachineDate() {
                commitMachineDate(this.handoverForm, this.occurTime).then(res => {
                    this.list = res.data.data
                    if (res.data.code == "0000") {
                      this.machineList.forEach((f) => {
                        if (f.code == this.machineInfo.code) {
                          f.useStatus = 1;
                        }
                      })
                        this.$message({
                            type: "success",
                            message: "提交成功"
                        });
                    }
                    this.list();
                    this.machineInfo.code = ''
                    this.handoverForm.shiftName = ''
                    this.handoverForm.workProductName = ''
                    this.handoverForm.groupName = ''
                    this.handoverForm.workIotLength = ''
                    this.handoverForm.workProductName = ''
                    this.handoverForm.workStartTime = ''
                    this.handoverForm.shiftName = ''
                    this.handoverForm.workProductName = ''
                    this.handoverForm.onDutyUserName = ''
                    this.handoverForm.postName = ''
                    this.handoverForm.afterWorkProductName = ''
                    this.handoverForm.afterWorkIotLength = ''
                    this.handoverForm.afterWorkEndTime = ''
                    this.handoverForm.forecastYield = ''
                    this.handoverForm.transferUserInformationList = ''
                    getMachine(this.workShopId, this.processId, this.occurTime).then(res => {
                        this.machineList = res.data.data;
                    })
                })
            },
            subMachineDateList() {
                let returnList = []
                this.tempList.forEach(function (item) {
                    let temp = {
                        "afterWorkEndTime": item.afterWorkEndTime,
                        "afterWorkIotLength": item.afterWorkIotLength,
                        "afterWorkProductId": item.afterWorkProductId,
                        "afterWorkProductName": item.afterWorkProductName,
                        "corporationId": item.corporationId,
                        "forecastYield": item.forecastYield,
                        "groupId": item.groupId,
                        "groupName": item.groupName,
                        "transferUserInformationList": item.transferUserInformationList,
                        "machineCode": item.machineCode,
                        "machineId": item.machineId,
                        "onDutyUserId": item.onDutyUserId,
                        "onDutyUserName": item.onDutyUserName,
                        "postId": item.postId,
                        "postName": item.postName,
                        "processId": item.processId,
                        "shiftId": item.shiftId,
                        "shiftName": item.shiftName,
                        "workIotLength": item.workIotLength,
                        "workProductId": item.workProductId,
                        "workProductName": item.workProductName,
                        "workShopId": item.workShopId,
                        "workShopName": item.workShopName,
                        "workStartTime": item.workStartTime,
                    }
                    returnList.push(temp)
                });
                commitMachineDateList(returnList).then(res => {
                    this.tempList = res.data.data
                    if (res.data.data.length > 0) {
                        this.$message({
                            message: "提交成功," + "有" + res.data.data.length + "数据有误，请查证！",
                            type: "success"
                        });
                    } else {
                        this.$message({
                            message: "提交成功",
                            type: "success"
                        });
                    }
                  this.machineList.forEach((f) => {
                    if (f.code == this.machineInfo.code) {
                      f.useStatus = 1;
                    }
                  })
                });
                getMachine(this.workShopId, this.processId, this.occurTime).then(res => {
                    this.machineList = res.data.data;
                    this.$forceUpdate()
                })
            },
            deleteRow(index, rows) {
                rows.splice(index, 1);
            },
            batch() {
                this.isIndex = 1
                this.showDanJi = false
                this.showPiLiang = true
                getTemplateMachineDate(this.workShopId, this.processId, this.occurTime).then(res => {
                    let template = this.templateList = res.data.data;
                    for (let templateKey in template) {
                        let obj1 = {
                            "afterWorkEndTime":  template[templateKey].afterWorkEndTime,
                            "afterWorkIotLength":  template[templateKey].afterWorkIotLength,
                            "afterWorkProductId":  template[templateKey].afterWorkProductId,
                            "afterWorkProductName":  template[templateKey].afterWorkProductName,
                            "corporationId":  template[templateKey].corporationId,
                            "forecastYield":  template[templateKey].forecastYield,
                            "groupId":  template[templateKey].groupId,
                            "groupName":  template[templateKey].groupName,
                            "transferUserInformationList":  template[templateKey].transferUserInformationList.map(i=>i.userId),
                            "machineCode":  template[templateKey].machineCode,
                            "machineId":  template[templateKey].machineId,
                            "onDutyUserId":  template[templateKey].onDutyUserId,
                            "onDutyUserName":  template[templateKey].onDutyUserName,
                            "postId":  template[templateKey].postId,
                            "postName":  template[templateKey].postName,
                            "processId": template[templateKey].processId,
                            "shiftId": template[templateKey].shiftId,
                            "shiftName": template[templateKey].shiftName,
                            "workIotLength": template[templateKey].workIotLength,
                            "workProductId": template[templateKey].workProductId,
                            "workProductName": template[templateKey].workProductName,
                            "workShopId": template[templateKey].workShopId,
                            "workShopName": template[templateKey].workShopName,
                            "workStartTime": template[templateKey].workStartTime,
                            "occurTime": template[templateKey].occurTime,
                            "beforeIotLength": template[templateKey].beforeIotLength,
                            "afterIotLength": template[templateKey].afterIotLength
                        }
                        this.tempList.push(obj1)
                    }

                })
            },
            standAlone() {
                this.isIndex = 0
                this.tempList = []
                this.showDanJi = true
                this.showPiLiang = false
            }

        }
    }
</script>

<style scoped lang="scss">
    /deep/ .el-card__header {
        color: #FFFFFF;
        background-color: #aaa;
    }

    .blue {
        background-color: #1f94f4;
    }

    .successionManagement {
        padding: 20px;
        background: rgba(245, 246, 248, 1);
        .border-none {
            border: none;
        }
        .bg7caff5 {
            background: #7CAFF5;
        }

        .top-heaer {
            background: #fff;
            padding: 10px;
            border-radius: 5px;
        }
        .lable {
            margin-right: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .d-flex {
            display: flex;
        }

        .select-box {
            padding-left: 30px;
            height: 70px;
            border-bottom: 1px solid rgba(151, 151, 151, 1);
            margin-bottom: 30px;
            .btn-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-right: 30px;
                .btn {
                    background: none;
                    color: rgba(0, 0, 0, .6);
                    border: none;
                }
                .btn-ac {
                    background: rgba(90, 155, 248, 1);
                    color: #fff;
                }
            }
        }
        .table {
            margin-top: 35px;
            /deep/ th {
                background: rgba(224, 226, 228, 1) !important;
                color: #000 !important;
            }
        }
    }
</style>
